import { Injectable } from '@angular/core'
import {
  _first,
  _isNotNullish,
  _last,
  _numberEnumValues,
  _objectKeys,
  _sortBy,
  _stringMapValues,
  IsoDate,
  localDate,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  CurrentPhaseId,
  CycleSummary,
  DailyEntryBM,
  Goal,
  HadSex,
  HardwareId,
  MedicalFlag,
  Mens,
  TemperatureFluctuationStatus,
  TestResult,
  UFColorCode,
  UFPredictionColorCode,
  UserFertility,
} from '@naturalcycles/shared'
import { getState } from '@src/app/srv/store.service'
import { ICON, ICON_BY_MENS_QUANTITY } from '../cnst/icons.cnst'

@Injectable({ providedIn: 'root' })
export class CycleStatService {
  public getCycleLengthData(): CycleLengthData | undefined {
    const uf = getState().userFertility
    const { clave, claveRMS, cycleSummaries, ncycles, flags } = uf

    // ncycles is never 0, "0 cycles tracked" equals ncycles = 1
    if (ncycles <= 1) return

    const variation = Math.round(claveRMS)
    const irregularLength = flags?.includes(MedicalFlag.CYCLE_LENGTH)
    const irregularVariation = flags?.includes(MedicalFlag.CYCLE_LENGTH_VARIATION)

    return {
      length: Math.round(clave),
      variation,
      irregularLength,
      irregularVariation,
      ...this.getCycleLengths(cycleSummaries?.filter(_isNotNullish)),
    }
  }

  public getPeriodData(): AveragePeriod | undefined {
    const { mdays, predictionMap, flags, ncycles } = getState().userFertility

    if (ncycles <= 1) return

    const length = Math.round(mdays)

    const irregularLength = flags?.includes(MedicalFlag.MENS_LENGTH)

    return {
      length,
      icons: this.getPredictedPeriodFlowIcons(length, predictionMap),
      irregularLength,
    }
  }

  public getFollicularData(): PhaseData | undefined {
    const {
      userFertility,
      account: { medicalStats, hwId },
      accountData,
    } = getState()

    if (!medicalStats.foundOvulation) return

    const { oave, oaveRMS, ncycles } = userFertility

    if (ncycles <= 1) return

    const { meanTemperature, temperatureFluctuation, fluctuationLevel } =
      accountData.temperatureFluctuation?.follicularPhase || {}

    return {
      length: Math.round(oave - 1),
      variation: Math.round(oaveRMS),
      meanTemperature,
      temperatureFluctuation,
      fluctuationLevel,
      tempVariationGlossaryAvailable: hwId === HardwareId.APPLE_WATCH,
    }
  }

  public getOvulationData(): OvulationData | undefined {
    const {
      userFertility: {
        oave,
        oaveRMS,
        cycleSummaries,
        noOCycles,
        predOvulationDays,
        ovulationDays,
        ncycles,
        colorMap,
        entryMap,
      },
      account,
    } = getState()
    const { foundOvulation, detectedOvulationsCount } = account.medicalStats

    if (!foundOvulation || ncycles <= 1) return

    const numAnovulatory = noOCycles.filter(Boolean).length
    const numOvulationNotConfirmed = this.getNumberUnconfirmedOvulations({
      noOCycles,
      predOvulationDays,
      ovulationDays,
      ncycles,
    })
    const cSummaries = cycleSummaries?.filter(_isNotNullish) || []

    return {
      cd: Math.round(oave),
      variation: Math.round(oaveRMS),
      numOvulation: detectedOvulationsCount || 0,
      numOvulationNotConfirmed,
      numAnovulatory,
      numSpottingNearOvulation: this.getSpottingsNearOvulation(colorMap, entryMap),
      ...this.getEarliestLatestOvulation(cSummaries),
    }
  }

  public getLutealData(): PhaseData | undefined {
    const {
      userFertility,
      account: { medicalStats, hwId },
      accountData,
    } = getState()

    if (!medicalStats.foundOvulation) return

    const { lpave, lpaveRMS, flags, ncycles } = userFertility

    if (ncycles <= 1) return

    const { meanTemperature, temperatureFluctuation, fluctuationLevel } =
      accountData.temperatureFluctuation?.lutealPhase || {}

    const irregularLength = flags?.includes(MedicalFlag.LUTEAL_LENGTH)

    return {
      length: Math.round(lpave),
      variation: Math.round(lpaveRMS),
      meanTemperature,
      temperatureFluctuation,
      fluctuationLevel,
      tempVariationGlossaryAvailable: hwId === HardwareId.APPLE_WATCH,
      irregularLength,
    }
  }

  public getTotalDataAdded(): TotalDataAdded {
    const {
      userFertility: { entryMap },
      account: { goal },
    } = getState()

    const entries = _stringMapValues(entryMap)

    return {
      mens: entries.filter(e => e.mens === Mens.MENSTRUATION).length,
      tests: {
        lhTestPositive: entries.filter(e => e.lhTest === TestResult.YES).length,
        lhTestNegative: entries.filter(e => e.lhTest === TestResult.NO).length,
        pregTestPositive: entries.filter(e => e.pregTest === TestResult.YES).length,
        pregTestNegative: entries.filter(e => e.pregTest === TestResult.NO).length,
      },
      sex: this.getTotalAddedSex(entries, goal),
    }
  }

  public getMeasurementThisCycle(): CycleMeasurements {
    const { colorMap, entryMap, ncycles } = getState().userFertility

    const daysThisCycle = _stringMapValues(colorMap).filter(color => {
      return color.nCycle === ncycles
    })

    const days = daysThisCycle.filter(key => {
      return entryMap[key.date]?.temperature
    }).length

    const percentage = days / daysThisCycle.length
    let level = 'my-data-measure-level-1'

    if (percentage > 0.8) level = 'my-data-measure-level-5'
    else if (percentage > 0.7) level = 'my-data-measure-level-4'
    else if (percentage > 0.6) level = 'my-data-measure-level-3'
    else if (percentage >= 0.4) level = 'my-data-measure-level-2'

    return {
      percentage: Math.round(percentage * 100) / 100, // rounded to two decimals
      days,
      level,
    }
  }

  private getTotalAddedSex(entries: DailyEntryBM[], goal?: Goal): TotalSexAdded {
    if (goal === Goal.PREVENT || goal === Goal.POSTPARTUM) {
      return {
        sexProtected: entries.filter(({ sex }) => sex === HadSex.YES_PROTECTED).length,
        sexUnprotected: entries.filter(({ sex }) => sex === HadSex.YES).length,
      }
    }

    return {
      sex: entries.filter(({ sex }) => sex === HadSex.YES || sex === HadSex.YES_PROTECTED).length,
    }
  }

  private getCycleLengths(cycleSummaries?: CycleSummary[]): Partial<CycleLengthData> {
    if (!cycleSummaries?.length) return {}

    const sorted = _sortBy(cycleSummaries, cs => cs.cycleLength)
    const lastSixCyclesSorted = _sortBy(cycleSummaries.slice(-6), cs => cs.cycleLength)

    return {
      longest: _last(lastSixCyclesSorted).cycleLength,
      longestEver: _last(sorted).cycleLength,
      shortest: _first(lastSixCyclesSorted).cycleLength,
      shortestEver: _first(sorted).cycleLength,
    }
  }

  private getEarliestLatestOvulation(cycleSummaries: CycleSummary[]): Partial<OvulationData> {
    if (!cycleSummaries.length) return {}

    const sorted = _sortBy(cycleSummaries, cs => cs.ovulationCD)
    const allCyclesSorted = sorted.filter(cs => cs.ovulationCD)
    const lastSixCyclesSorted = _sortBy(cycleSummaries.slice(-6), cs => cs.ovulationCD).filter(
      cs => cs.ovulationCD,
    )

    if (!lastSixCyclesSorted.length) return {}

    return {
      earliest: _first(lastSixCyclesSorted).ovulationCD,
      earliestEver: _first(allCyclesSorted).ovulationCD,
      latest: _last(lastSixCyclesSorted).ovulationCD,
      latestEver: _last(allCyclesSorted).ovulationCD,
    }
  }

  private getPredictedPeriodFlowIcons(
    length: number,
    predictionMap: StringMap<UFPredictionColorCode>,
  ): ICON[] {
    const predictions = _stringMapValues(predictionMap)

    return Array.from({ length }).map((_, i) => {
      const prediction = predictions.find(p => p.cd === i + 1)

      if (!prediction?.mensQuantity) return ICON.MENSTRUATION

      return ICON_BY_MENS_QUANTITY[prediction.mensQuantity] || ICON.MENSTRUATION
    })
  }

  private getSpottingsNearOvulation(
    colorMap: StringMap<UFColorCode>,
    entryMap: StringMap<DailyEntryBM>,
  ): number {
    let spottings = 0
    const ovulationDates = Object.keys(entryMap).filter(k => {
      if (colorMap[k]?.code.ovulation) return k
    }) as IsoDate[]

    for (const ovulationDate of ovulationDates) {
      const dayBefore = entryMap[localDate(ovulationDate).minus(1, 'day').toISODate()]
      const dayAfter = entryMap[localDate(ovulationDate).plus(1, 'day').toISODate()]

      if (entryMap[ovulationDate]?.mens === Mens.SPOTTING) spottings++
      if (dayBefore && dayBefore.mens === Mens.SPOTTING) spottings++
      if (dayAfter && dayAfter.mens === Mens.SPOTTING) spottings++
    }

    return spottings
  }

  private getNumberUnconfirmedOvulations(
    uf: Pick<UserFertility, 'predOvulationDays' | 'ovulationDays' | 'noOCycles' | 'ncycles'>,
  ): number {
    const { predOvulationDays, ovulationDays, noOCycles, ncycles } = uf

    return _objectKeys(predOvulationDays).filter(key => {
      // Dont compare current or future cycles
      if ((key as number) >= ncycles) return false

      // If prediction = null & ovuDays = undefined
      if (!predOvulationDays[key] && !ovulationDays[key]) {
        return false
      }

      // Check if prediction === ovuDays and it is not a confirmed anovulatory cycle
      return predOvulationDays[key] !== ovulationDays[key] && !noOCycles[key]
    }).length
  }
}

export const cyclePhaseItems: (CurrentPhaseId | 'cycle-length')[] = [
  ..._numberEnumValues(CurrentPhaseId),
  'cycle-length',
]
export type CycleInfoId = (typeof cyclePhaseItems)[number]

export interface CycleLengthData {
  length: number
  variation: number
  irregularLength?: boolean
  irregularVariation?: boolean
  longest?: number
  longestEver?: number
  shortest?: number
  shortestEver?: number
}

export enum CycleRating {
  REGULAR = 1,
  IRREGULAR = 2,
  HIGHLY_IRREGULAR = 3,
}

interface AveragePeriod {
  length: number
  icons: ICON[]
  irregularLength?: boolean
}

interface PhaseData extends Partial<TemperatureFluctuationStatus> {
  length: number
  variation: number
  tempVariationGlossaryAvailable?: boolean
  irregularLength?: boolean
}

interface OvulationData {
  cd: number
  variation: number
  earliest?: number
  earliestEver?: number
  latest?: number
  latestEver?: number
  numOvulation?: number
  numOvulationNotConfirmed?: number
  numAnovulatory?: number
  numSpottingNearOvulation?: number
}

interface TotalDataAdded {
  mens: number
  tests: TotalTestsAdded
  sex: TotalSexAdded
}

export interface TotalTestsAdded {
  lhTestPositive: number
  lhTestNegative: number
  pregTestPositive: number
  pregTestNegative: number
}

export interface TotalSexAdded {
  sex?: number
  sexProtected?: number
  sexUnprotected?: number
}

interface CycleMeasurements {
  percentage: number
  days: number
  level: string
}
